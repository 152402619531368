import "./index.scss";

import React from "react";

const ReferralBenefits = ({ withMobile }) => (
  <div className={`referral-benefits ${withMobile ? "with-mobile" : ""}`}>
    <h2>Earn passive income with a referral programme</h2>
    <p className="referral-benefits__earn-lbl">
      What you earn is based on the subscription plan of your referrals.
    </p>
    <div className="referral-benefits__offers">
      <div className="referral-benefits__offer">
        <p>1-month offer</p>
        <div>
          <p>New sign-up</p>
          <p>
            <span>100%</span>
          </p>
        </div>
        <div>
          <p>Renewal</p>
          <p>
            <span>30%</span>
          </p>
        </div>
      </div>
      <div className="referral-benefits__offer">
        <p>1-year offer</p>
        <div>
          <p>New sign-up</p>
          <p>
            <span>40%</span>
          </p>
        </div>
        <div>
          <p>Renewal</p>
          <p>
            <span>30%</span>
          </p>
        </div>
      </div>
      <div className="referral-benefits__offer">
        <p>2-year offer</p>
        <div>
          <p>New sign-up</p>
          <p>
            <span>40%</span>
          </p>
        </div>
        <div>
          <p>Renewal</p>
          <p>
            <span>30%</span>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ReferralBenefits;
