import React from "react";

import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { register } from "../../../../api";
import Button from "../../Button";

const RegisterForm = ({
  //  referralCode,
  switchToLogin,
  setIsLoading,
  //  switchToConfirmEmail,
  onSuccess,
  scrollToPlans,
  close,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;
    const passwordConfirm = e.target.passwordConfirm.value;
    //const referralCode = e.target.referralCode.value;

    const data = {
      email,
      password,
      passwordConfirm,
      // ...(referralCode ? { referralCode } : {}),
    };

    setIsLoading(true);

    register(data)
      .then((response) => {
        //switchToConfirmEmail();
        enqueueSnackbar("You have successfully registered!", {
          variant: "success",
        });
        const accessToken = response.data.token;
        localStorage.setItem("accessToken", accessToken);
        navigate("/");

        onSuccess ? onSuccess() : scrollToPlans && scrollToPlans();
        close();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form id="register-form" onSubmit={handleSubmit}>
      <h2 className="base-modal__title">Create your account</h2>
      <p className="lh-150 mb-40">
        Please enter your email and create a password. With these credentials,
        you will be able to access the app and your personal profile.
      </p>

      <TextField
        required
        name="email"
        type="email"
        id="outlined-basic"
        label="Your e-mail"
        variant="outlined"
        className="text-field"
      />
      <TextField
        required
        name="password"
        type="password"
        id="outlined-basic2"
        label="Create password"
        variant="outlined"
        className="text-field"
      />
      <TextField
        required
        name="passwordConfirm"
        type="password"
        id="outlined-basic3"
        label="Confirm password"
        variant="outlined"
        className="text-field"
      />

      {/*
      <p>Enter the referral code if you have</p>
      <TextField
        name="referralCode"
        type="text"
        id="outlined-basic3"
        label="Referral code"
        variant="outlined"
        className="text-field"
        defaultValue={referralCode}
      />*/}

      <Button mode="secondary" type="submit" fullWidth>
        {onSuccess ? "Next step" : "Confirm"}
      </Button>
      <p className="t-14 mt-10">
        By clicking the "Confirm" button, you agree to{" "}
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="/terms" target="_blank" rel="noreferrer" className="link">
          Terms and conditions
        </a>
      </p>
      <p className="lh-150 mt-20 mb-5">Or</p>
      <Button mode="primary" onClick={switchToLogin} fullWidth>
        Log in
      </Button>
    </form>
  );
};

export default RegisterForm;
