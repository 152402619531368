import React from "react";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import { unSubscribe } from "../../../api";

import { useStore } from "../../../store";

import { LoaderV2 } from "../../ui/Loader";

const SubscriptionsTab = () => {
  const {
    mySubscription,
    getMySubscription,
    getMySubscriptionLoading: isLoading,
  } = useStore();

  const { enqueueSnackbar } = useSnackbar();

  const formattedDate = (date) => format(date, "dd MMM yyyy");

  const handleUnsubscribe = () => {
    unSubscribe()
      .then(() => {
        enqueueSnackbar("Successfully unsubscribed", { variant: "success" });
        getMySubscription();
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      });
  };

  return (
    <div className="profile-subscriptions">
      <h2>Subscriptions</h2>
      <div className="profile-subscriptions__box">
        {isLoading ? (
          <LoaderV2 />
        ) : mySubscription ? (
          <div
            className={`profile-subscriptions__item ${
              !mySubscription?.is_subscribed ? "inactive" : ""
            }`}
          >
            <h3>Plan: {mySubscription?.plan_name}</h3>
            <div className="profile-subscriptions__item-inner">
              <p>
                Start of subscription:{" "}
                {mySubscription?.subscription_start
                  ? formattedDate(mySubscription.subscription_start)
                  : null}
              </p>
              {mySubscription?.is_subscribed &&
              !mySubscription.subscription_end ? (
                <p>{/*Next payment: <span>{"nextPayment"}</span>*/}</p>
              ) : (
                <p>
                  Next payment:{" "}
                  {mySubscription?.subscription_end
                    ? formattedDate(mySubscription.subscription_end)
                    : null}
                </p>
              )}
            </div>

            {mySubscription?.is_subscribed && (
              <button
                className="profile-subscriptions__btn"
                onClick={handleUnsubscribe}
              >
                Unsubscribe
              </button>
            )}
          </div>
        ) : (
          <p className="profile-subscriptions__empty">
            There are no subscriptions
          </p>
        )}
      </div>
    </div>
  );
};

export default SubscriptionsTab;
