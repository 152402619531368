import "./index.scss";

import React, { useEffect } from "react";

import BaseModal from "../BaseModal";

import { DOWNLOAD_LINKS } from "../../../const/config";

import GooglePlayLogo from "../../../assets/stores/google-play.svg";
import AppleStoreLogo from "../../../assets/stores/apple.svg";
import ChromeLogo from "../../../assets/stores/chrome.svg";
import QRAndroidImg from "../../../assets/qr-codes/android-qr.svg";
import QrIOSImg from "../../../assets/qr-codes/ios-qr.png";
import QrExtension from "../../../assets/qr-codes/extension.svg";

const SuccessPaymentPopup = ({ close }) => {
  useEffect(() => {
    // Google Analytics script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      gtag('event', 'conversion', {
          'send_to': 'AW-16685472120/nh3YCKO4h84ZEPiyoJQ-',
          'value': 1.0,
          'currency': 'USD',
          'transaction_id': ''
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <BaseModal close={close} wrapperCls="success-pay-popup">
      <div>
        <div className="success-pay-popup__content">
          <h2>Congratulations!</h2>
          <p className="success-pay-popup__lbl">Subscription was successful</p>
          <div>
            <p className="success-pay-popup__desc">
              You can download the app and log in with the credentials you
              entered during registration.
            </p>
            <div className="profile-download__box">
              {/** iOS */}
              <div className="profile-download__item">
                <p>iOS App:</p>
                <div
                  className="profile-download__btn t_download-google-play"
                  onClick={() => window.open(DOWNLOAD_LINKS.IOS)}
                >
                  <img src={AppleStoreLogo} alt="Apple Store" />
                  <div>
                    <p className="profile-download__btn-label">GET IT ON</p>
                    <p className="profile-download__btn-store">App Store</p>
                  </div>
                </div>
                <img src={QrIOSImg} alt="iOS" />
              </div>

              {/** ANDROID */}
              <div className="profile-download__item">
                <p>Android App:</p>
                <div
                  className="profile-download__btn t_download-google-play"
                  onClick={() => window.open(DOWNLOAD_LINKS.ANDROID)}
                >
                  <img src={GooglePlayLogo} alt="Google Play" />
                  <div>
                    <p className="profile-download__btn-label">GET IT ON</p>
                    <p className="profile-download__btn-store">Google Play</p>
                  </div>
                </div>
                <img src={QRAndroidImg} alt="android" />
              </div>

              {/** CHROME EXTENSION */}
              <div className="profile-download__item">
                <p>Browser Extension:</p>
                <div
                  className="profile-download__btn t_download-google-play"
                  onClick={() => window.open(DOWNLOAD_LINKS.CHROME_EXT)}
                >
                  <img src={ChromeLogo} alt="Chrome" />
                  <div>
                    <p className="profile-download__btn-label">Download for</p>
                    <p className="profile-download__btn-store">Chrome</p>
                  </div>
                </div>
                <img src={QrExtension} alt="extension" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default SuccessPaymentPopup;
