import React from "react";
import "./index.scss";

const BurgerBtn = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => {
  return (
    <div
      className={isMobileMenuOpen ? "burgerBtn active" : "burgerBtn"}
      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
    >
      <span></span>
    </div>
  );
};

export default BurgerBtn;
