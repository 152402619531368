import React, { useState } from "react";

import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoaderV2 } from "../Loader";
import BaseModal from "../BaseModal";
import Button from "../Button";

import { resetPasswordInitiate, resetPasswordConfirm } from "../../../api";

const ResetPassPopup = ({ currentMode, email, code, close }) => {
  /** modes: [initial, sent-code, new-pass, success] */
  const [mode, setMode] = useState(currentMode ?? "initial");

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSendCode = (e) => {
    e.preventDefault();

    const email = e.target.email.value;

    const data = { email };

    setIsLoading(true);

    resetPasswordInitiate(data)
      .then(() => {
        setMode("sent-code");
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCreateNewPass = (e) => {
    e.preventDefault();

    const newPassword = e.target.password.value;
    const passwordNewConfirm = e.target.passwordConfirm.value;

    const data = { code, newPassword, passwordNewConfirm };

    setIsLoading(true);

    resetPasswordConfirm(data)
      .then(() => {
        setMode("success");
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BaseModal close={close}>
      <div>
        {isLoading && <LoaderV2 />}

        {mode === "initial" ? (
          <form id="login-form" onSubmit={handleSendCode}>
            <h2 className="base-modal__title">Reset password</h2>
            <p className="mb-40">
              To reset your password, enter the email address you used to
              register your account.
            </p>

            <TextField
              required
              name="email"
              type="email"
              id="outlined-basic4"
              label="Your email"
              variant="outlined"
              className="text-field"
              defaultValue={email ?? null}
            />

            <Button mode="secondary" type="submit" fullWidth className="mt-20">
              Reset password
            </Button>
          </form>
        ) : mode === "sent-code" ? (
          <div>
            <h2 className="base-modal__title">Reset Password</h2>
            <p className="t-18">
              We have sent you a link by email. Please click on the link and
              change your password.
            </p>
          </div>
        ) : mode === "new-pass" ? (
          <form onSubmit={handleCreateNewPass}>
            <h2 className="base-modal__title">Change Password</h2>
            <p className="mb-40">Create your new password</p>

            <TextField
              required
              name="password"
              type="password"
              id="outlined-basic2"
              label="Your new password"
              variant="outlined"
              className="text-field"
            />
            <TextField
              required
              name="passwordConfirm"
              type="password"
              id="outlined-basic3"
              label="Confirm password"
              variant="outlined"
              className="text-field"
            />

            <Button mode="secondary" type="submit" fullWidth className="mt-20">
              Confirm
            </Button>
          </form>
        ) : mode === "success" ? (
          <div>
            <h2 className="base-modal__title">Password Changed</h2>
            <p className="mb-40 t-18">
              You can now log in with your new password.
            </p>

            <Button mode="secondary" onClick={close} fullWidth>
              Ok
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </BaseModal>
  );
};

export default ResetPassPopup;
