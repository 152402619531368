import "./index.scss";

import closeIcon from "../../../assets/close_icon.svg";

const BaseModal = ({ wrapperCls, children, close }) => (
  <div className={`base-modal ${wrapperCls}`}>
    {children}

    {close && (
      <div className="base-modal__close">
        <img src={closeIcon} onClick={close} alt="close" />
      </div>
    )}
  </div>
);

export default BaseModal;
