import API from "./api-base";

import axios from "axios";

/** AUTH */

const register = ({ email, password, passwordConfirm }) =>
  API.post("/auth/register", { email, password, passwordConfirm });

const emailVerify = ({ code }) => API.post("/auth/verify", { code });

const changePassword = ({ password, newPassword, newPasswordConfirm }) =>
  API.post("/auth/change-password", {
    password,
    newPassword,
    newPasswordConfirm,
  });

const logIn = ({ email, password }) =>
  API.post("/auth/login", { email, password });

const logOut = () => API.post("/auth/session");

const resetPasswordInitiate = ({ email }) =>
  API.post("/auth/reset-password/initiate", { email });

const resetPasswordCheck = ({ code }) =>
  API.post("/auth/reset-password/check", { code });

const resetPasswordConfirm = ({ code, newPassword, passwordNewConfirm }) =>
  API.post("/auth/reset-password/confirm", {
    code,
    newPassword,
    passwordNewConfirm,
  });

/** USER */

const getMeAPI = () => API.get("/user/me");

/** SERVERS */

const getServersAPI = () => API.get("/servers/flags");

/** PAYMENT */

const getPaymentHistoryAPI = () =>
  API.get("/subscription/user/payment-history");

const getMySubscriptions = () => API.get("/subscription/my-subscription");

const unSubscribe = () => API.post("/subscription/unsubscribe");

const getProductsStripeAPI = () =>
  API.get("/subscription/stripe/product-pricing");

const getProductsPaypalAPI = () =>
  API.get("/subscription/paypal/product-pricing");

const subscribeStripe = ({ plan }) =>
  API.post("/subscription/stripe/subscribe", { plan });

const subscribePaypal = ({ plan }) =>
  API.post("/subscription/paypal/subscribe", { plan });

const subscribeStripeNA = ({ plan, email }) =>
  API.post("/subscription/non-auth/stripe/subscribe ", { plan, email });

/** PUBLIC */

const getIpInfoAPI = () => axios.get("https://ipinfo.io/json");

export {
  /** AUTH */
  register,
  emailVerify,
  changePassword,
  logIn,
  logOut,
  resetPasswordInitiate,
  resetPasswordCheck,
  resetPasswordConfirm,
  /** USER */
  getMeAPI,
  /** SERVERS */
  getServersAPI,
  /** PAYMENT */
  subscribeStripe,
  subscribePaypal,
  subscribeStripeNA,
  getProductsStripeAPI,
  getProductsPaypalAPI,
  getPaymentHistoryAPI,
  getMySubscriptions,
  unSubscribe,
  /** PUBLIC */
  getIpInfoAPI,
};
