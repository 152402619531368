import React, { useEffect, useRef } from "react";
import "./index.scss";
import ben1 from "../../../assets/benefit1.svg";
import ben2 from "../../../assets/benefit2.svg";
import ben3 from "../../../assets/benefit3.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import {
  getRem,
  isDesktopMedia,
  isMobileMedia,
  isTabletMedia,
} from "../../../utils";

gsap.registerPlugin(ScrollTrigger);

const benefitsData = [
  {
    id: 1,
    icon: ben1,
    title: "One account for several devices",
    text: "Use one account on several devices. For example, on Android or iOS smartphones, in a Google Chrome extension, or on a Windows desktop.",
  },
  {
    id: 2,
    icon: ben2,
    title: "Wide range of servers",
    text: "Experience digital freedom and security with our high-speed servers located around the world.",
  },
  {
    id: 3,
    icon: ben3,
    title: "High data transfer rate",
    text: "Experience digital freedom and security with our high-speed servers located around the world. ",
  },
];

const AboutSection = () => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();
  const isDesktop = isDesktopMedia();

  const listRef = useRef(null);

  useEffect(() => {
    const items = listRef.current.children;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: listRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    if (isMobile || isTablet) {
      tl.to([...items], {
        top: 0,
        opacity: 1,
        duration: 1,
        ease: "power2.inOut",
      });
    } else if (isDesktop) {
      tl.to(listRef.current, {
        duration: 1,
        opacity: 1,
        width: getRem(1200),
        ease: "power2.inOut",
      });
    }
  }, []);

  return (
    <section className="about_section" id="about">
      <h2 className="about_title">About VPN4U</h2>
      <p className="about_subtitle">Why should use VPN4U?</p>
      <ul className="about_benefits_list" ref={listRef}>
        {benefitsData.map(({ id, icon, title, text }) => {
          const titleWords = title.split(" ");
          const firstTwoWords = titleWords.slice(0, 2).join(" ");
          const restOfText = titleWords.slice(2).join(" ");
          return (
            <li key={id} className="about_benefits_listItem">
              <img src={icon} alt={`benefit ${id}`} />
              <div>
                <p className="title">
                  {firstTwoWords} <br />
                  <span>{restOfText}</span>
                </p>
                <p className="text">{text}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default AboutSection;
