import "./index.scss";
import React from "react";

const RadioButton = ({ ...props }) => (
  <div className="radiobutton">
    <input type="radio" className="radiobutton__input" {...props} />
    <span className="radiobutton__checkmark" />
  </div>
);

export default RadioButton;
