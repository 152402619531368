import "./index.scss";

import { Oval } from "react-loader-spinner";

const Loader = ({ color, secondaryColor, cls }) => {
  return (
    <Oval
      color={color ?? "#fff"}
      wrapperStyle={{}}
      wrapperClass={cls ?? "loader__spinner"}
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor={secondaryColor ?? "#c9c9c9"}
      strokeWidth={4}
      strokeWidthSecondary={4}
    />
  );
};

const LoaderV2 = () => {
  return (
    <div className="loader-v2">
      <Oval
        color="#fff"
        wrapperStyle={{}}
        wrapperClass="loader__spinner"
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#c9c9c9"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    </div>
  );
};

export { Loader, LoaderV2 };
