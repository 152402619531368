const API_URL = process.env.REACT_APP_API_URL;

const DOWNLOAD_LINKS = {
  ANDROID: "https://play.google.com/store/apps/details?id=com.vpn4",
  IOS: "https://apps.apple.com/ua/app/vpn4u-fast-and-secure-vpn/id6503442393",
  CHROME_EXT:
    "https://chromewebstore.google.com/detail/vpn4uio/ikfbplbmhdhmdameefcgfflemilobdpg",
  DESKTOP: "",
};

export { API_URL, DOWNLOAD_LINKS };
