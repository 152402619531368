import { enqueueSnackbar } from "notistack";
import clipboardCopy from "clipboard-copy";

const isAuthorizedUser = () => !!localStorage.getItem("accessToken");

const getRem = (size) => `${size / 16}rem`;
const isMobileMedia = () => window.matchMedia("(max-width: 767px)").matches;
const isTabletMedia = () =>
  window.matchMedia("(min-width: 768px) and (max-width: 1023px)").matches;
const isDesktopMedia = () => window.matchMedia("(min-width: 1024px)").matches;

const copyToClipboard = (text, successMessage) => {
  clipboardCopy(text)
    .then(() => {
      enqueueSnackbar(successMessage, {
        variant: "success",
      });
    })
    .catch(() => {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    });
};

const getClsForPayButton = (type) => {
  switch (type) {
    case "monthly_standart":
      return "t_pay-1-month-btn";
    case "yearly_standart":
      return "t_pay-12-months-btn";
    case "two_year_standart":
      return "t_pay-24-months-btn";
    default:
      return "";
  }
};

// Authorization wrapper func
const requiredAuth = (func, authorizeRequest) => {
  const isAuthorized = isAuthorizedUser();

  return () => {
    isAuthorized ? func() : authorizeRequest({ onSuccess: func });
  };
};

/** FIX POPUP BLOCKING IN SAFARI
 *
 * Popup Blocking Due to Asynchronous JavaScript Execution
 */
const openInNewTab = (url) => {
  var newWindow = window.open(url, "_blank");

  if (
    !newWindow ||
    newWindow.closed ||
    typeof newWindow.closed === "undefined"
  ) {
    window.location.href = url;
  }
};

export {
  isAuthorizedUser,
  getRem,
  isMobileMedia,
  isTabletMedia,
  isDesktopMedia,
  copyToClipboard,
  getClsForPayButton,
  requiredAuth,
  openInNewTab,
};
