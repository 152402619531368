import "./styles/base.scss";
import "./styles/common.scss";

import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { SnackbarProvider } from "notistack";

import HomePage from "./components/HomePage";
import ProfilePage from "./components/ProfilePage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import TermsPage from "./components/TermsPage";
import PaywallPage from "./components/PaywallPage";

const App = () => {
  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/subscription" element={<PaywallPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;
