import "./index.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../../assets/logo-vpn4u.svg";

const Footer = ({ isHomePage, isProtectedIp }) => {
  const navigate = useNavigate();

  const scrollToPlans = () => {
    if (isHomePage) {
      const getPlanEl = document.getElementById("prices");
      getPlanEl &&
        getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      navigate("/");
    }
  };

  const scrollToDownload = () => {
    const downloadEl = document.getElementById("download");
    downloadEl &&
      downloadEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer_topBox">
          <a href="/">
            <img src={logo} className="footer_logo" alt="logo" />
          </a>
          <nav className="footer_nav">
            <a href="/#about" className="footer_navLink">
              About VPN4U
            </a>
            <a href="/#prices" className="footer_navLink">
              Prices
            </a>
            <a href="/#download" className="footer_navLink">
              Download VPN
            </a>
            <a href="/#reviews" className="footer_navLink">
              Reviews
            </a>
            {/*
            <a href="/#faq" className="footer_navLink">
              FAQ
            </a>
            */}
          </nav>
          <button
            className="default-button"
            onClick={() =>
              isProtectedIp ? scrollToDownload() : scrollToPlans()
            }
          >
            Get Started
          </button>
        </div>
        <div className="footer_bottomBox">
          <p>© 2024 vpn4u.io, All Rights Reserved</p>
          <p>Made by Quanta Tech Systems LLC</p>
          <div className="footer_termsBox">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms">Terms and Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
