import axios from "axios";

import { API_URL } from "./const/config";

const API = axios.create({
  baseURL: API_URL,
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      window.location.replace("/");
      localStorage.clear();
    }

    // For other errors, reject the promise
    console.error("Error in response interceptor:", error);
    return Promise.reject(error);
  }
);

export default API;
