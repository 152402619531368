import "./index.scss";

import React, { useEffect, useRef } from "react";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { DOWNLOAD_LINKS } from "../../../const/config";

import mobile from "../../../assets/mobile1.png";
import GooglePlayLogo from "../../../assets/stores/google-play.svg";
import AppleStoreLogo from "../../../assets/stores/apple.svg";
import ChromeLogo from "../../../assets/stores/chrome.svg";
import QRAndroidImg from "../../../assets/qr-codes/android-qr.svg";
import QrIOSImg from "../../../assets/qr-codes/ios-qr.png";
import QrExtension from "../../../assets/qr-codes/extension.svg";

const FeaturesSection = () => {
  const imgRef = useRef(null);
  const progress1_ref = useRef(null);
  const progress2_ref = useRef(null);
  const progress3_ref = useRef(null);
  const bar1_ref = useRef(null);
  const bar2_ref = useRef(null);
  const bar3_ref = useRef(null);
  const homeDownloadRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: imgRef.current,
        start: "top 50%",
        // markers: true,
      },
    });

    tl.to(imgRef.current, {
      opacity: 1,
      left: "0%",
      duration: 1,
    })
      .to(progress1_ref.current, {
        width: "90%",
        duration: 2,
        delay: 1,
      })
      .to(
        progress2_ref.current,
        {
          width: "95%",
          duration: 2,
        },
        "<"
      )
      .to(
        progress3_ref.current,
        {
          width: "100%",
          duration: 2,
        },
        "<"
      )
      .to(
        bar1_ref.current,
        {
          width: "90%",
          duration: 2,
        },
        "<"
      )
      .to(
        bar2_ref.current,
        {
          width: "95%",
          duration: 2,
        },
        "<"
      )
      .to(
        bar3_ref.current,
        {
          width: "100%",
          duration: 2,
        },
        "<"
      );

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: homeDownloadRef.current,
        start: "top 50%",
      },
    });

    tl2.fromTo(
      ".home-download__item",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.7,
        stagger: 0.7,
      }
    );

    return () => {
      tl.kill();
      tl2.kill();
    };
  }, []);

  return (
    <>
      <div className="home-download" ref={homeDownloadRef} id="download">
        <h2>Download VPN4U</h2>
        <p className="home-download__desc">Use VPN4U on a variety of devices</p>
        <div className="home-download__box">
          {/** iOS */}
          <div className="home-download__item">
            <p>iOS App:</p>
            <div
              className="home-download__btn t_download-google-play"
              onClick={() => window.open(DOWNLOAD_LINKS.IOS)}
            >
              <img src={AppleStoreLogo} alt="Apple Store" />
              <div>
                <p className="home-download__btn-label">GET IT ON</p>
                <p className="home-download__btn-store">App Store</p>
              </div>
            </div>
            <img src={QrIOSImg} alt="iOS" />
          </div>

          {/** ANDROID */}
          <div className="home-download__item">
            <p>Android App:</p>
            <div
              className="home-download__btn t_download-google-play"
              onClick={() => window.open(DOWNLOAD_LINKS.ANDROID)}
            >
              <img src={GooglePlayLogo} alt="Google Play" />
              <div>
                <p className="home-download__btn-label">GET IT ON</p>
                <p className="home-download__btn-store">Google Play</p>
              </div>
            </div>
            <img src={QRAndroidImg} alt="android" />
          </div>

          {/** CHROME EXTENSION */}
          <div className="home-download__item">
            <p>Browser Extension:</p>
            <div
              className="home-download__btn t_download-google-play"
              onClick={() => window.open(DOWNLOAD_LINKS.CHROME_EXT)}
            >
              <img src={ChromeLogo} alt="Chrome" />
              <div>
                <p className="home-download__btn-label">Download for</p>
                <p className="home-download__btn-store">Chrome</p>
              </div>
            </div>
            <img src={QrExtension} alt="chrome extension" />
          </div>
        </div>
      </div>

      <section className="features_section">
        <div className="features_card">
          <div className="features_imgWrapper">
            <img src={mobile} alt="mobile" ref={imgRef} />
          </div>
          <div className="features_textBox">
            <h3 className="features_title">VPN4U features</h3>

            <p className="features_subtitle">
              High performance
              <br />
              for your comfort
            </p>
          </div>
          <ul className="features_progressbars">
            <li className="bar_wrapper">
              <div className="bar_text" ref={bar1_ref}>
                <p>Speed</p>
                <p>90%</p>
              </div>
              <div className="bar_block">
                <span className="bar_progress" ref={progress1_ref}></span>
              </div>
            </li>
            <li className="bar">
              <div className="bar_text" ref={bar2_ref}>
                <p>Security</p>
                <p>95%</p>
              </div>
              <div className="bar_block">
                <span className="bar_progress" ref={progress2_ref}></span>
              </div>
            </li>
            <li className="bar_wrapper">
              <div className="bar_text" ref={bar3_ref}>
                <p>Privacy</p>
                <p>100%</p>
              </div>
              <div className="bar_block">
                <span className="bar_progress" ref={progress3_ref}></span>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default FeaturesSection;
