import React, { useState } from "react";
import { Modal } from "@mui/material";

import { useStore } from "../../../store";

import Button from "../../ui/Button";
import ChangePasswordPopup from "../../ui/ChangePasswordPopup";
import ResetPassPopup from "../../ui/ResetPassPopup";

const SettingsTab = () => {
  const [showChangePassPopup, setShowChangePassPopup] = useState(false);
  const [showResetPassPopup, setShowResetPassPopup] = useState(false);

  const { user } = useStore();

  return (
    <div className="profile-settings">
      <div className="profile-settings__box">
        <p className="profile-settings__email">
          Your email: <span>{user.email}</span>
        </p>
        <Button
          mode="secondary"
          onClick={() => setShowChangePassPopup(true)}
          ext
        >
          Change Password
        </Button>
      </div>

      <Modal
        open={showChangePassPopup}
        onClose={() => setShowChangePassPopup(false)}
      >
        <ChangePasswordPopup
          close={() => setShowChangePassPopup(false)}
          showResetPassPopup={() => setShowResetPassPopup(true)}
        />
      </Modal>
      <Modal
        open={showResetPassPopup}
        onClose={() => setShowResetPassPopup(false)}
      >
        <ResetPassPopup
          close={() => setShowResetPassPopup(false)}
          email={user.email}
          currentMode="initial"
        />
      </Modal>
    </div>
  );
};

export default SettingsTab;
