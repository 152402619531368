import { create } from "zustand";
import { enqueueSnackbar } from "notistack";

import {
  getMeAPI,
  getPaymentHistoryAPI,
  getMySubscriptions,
  getIpInfoAPI,
  getProductsPaypalAPI,
  getProductsStripeAPI,
  getServersAPI,
} from "./api";

const useStore = create((set) => ({
  user: {
    email: "",
    referralBalance: "",
    referralCode: "",
    isSubscribed: false,
  },
  getMe: () => {
    getMeAPI()
      .then((response) => {
        const userData = response.data;

        set(() => ({ user: userData }));
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      });
  },

  paymentHistory: [],
  getPaymentHistory: () => {
    set({ getPaymentHistoryLoading: true });
    getPaymentHistoryAPI()
      .then((r) => {
        const txs = r.data.transactions.slice().reverse();
        set({ paymentHistory: txs });
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response?.data.message ?? e, { variant: "error" });
      })
      .finally(() => {
        set({ getPaymentHistoryLoading: false });
      });
  },
  getPaymentHistoryLoading: false,

  mySubscription: null,
  getMySubscriptionLoading: false,
  getMySubscription: () => {
    set({ getMySubscriptionLoading: true });
    getMySubscriptions()
      .then((r) => {
        const subs = r.data.subscription?.length
          ? r.data.subscription.find((item) => item?.is_subscribed)
          : null;

        set({ mySubscription: subs });
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar(e.response?.data.message, { variant: "error" });
      })
      .finally(() => {
        set({ getMySubscriptionLoading: false });
      });
  },

  ipInfo: {
    ip: "",
    org: "",
  },
  getIpInfoLoading: false,
  getIpInfo: () => {
    set({ getIpInfoLoading: true });
    getIpInfoAPI()
      .then((resp) => {
        set({ ipInfo: resp.data });
      })
      .finally(() => {
        set({ getIpInfoLoading: false });
      });
  },

  ONE_MONTH_PLAN: {
    stripe: {
      unit_amount: 6.49,
      unit_amount_monthly: 6.49,
      discount: 0,
    },
    paypal: {},
  },
  ONE_YEAR_PLAN: {
    stripe: {
      unit_amount: 29.88,
      unit_amount_monthly: 2.49,
      discount: 62,
    },
    paypal: {},
  },
  TWO_YEAR_PLAN: {
    stripe: {
      unit_amount: 47.76,
      unit_amount_monthly: 1.99,
      discount: 69,
    },
    paypal: {},
  },
  getProductsLoading: false,
  getProducts: () => {
    set({ productsLoading: true });
    getProductsStripeAPI()
      .then((r) => {
        const prices = r.data.prices;

        let oneMonthPlan, oneYearPlan, twoYearPlan;

        prices.forEach((price) => {
          if (price.recurring) {
            if (
              price.recurring.interval === "month" &&
              price.recurring.interval_count === 1
            ) {
              oneMonthPlan = price;
            } else if (
              price.recurring.interval === "year" &&
              price.recurring.interval_count === 1
            ) {
              oneYearPlan = price;
            } else if (
              price.recurring.interval === "year" &&
              price.recurring.interval_count === 2
            ) {
              twoYearPlan = price;
            }
          }
        });

        set((state) => ({
          ONE_MONTH_PLAN: {
            paypal: state.ONE_MONTH_PLAN.paypal,
            stripe: {
              ...oneMonthPlan,
              unit_amount_monthly: oneMonthPlan.unit_amount.toFixed(2),
            },
          },
          ONE_YEAR_PLAN: {
            paypal: state.ONE_YEAR_PLAN.paypal,
            stripe: {
              ...oneYearPlan,
              unit_amount_monthly: (oneYearPlan.unit_amount / 12).toFixed(2),
              discount:
                100 -
                Math.round(
                  (oneYearPlan.unit_amount / 12 / oneMonthPlan.unit_amount) *
                    100
                ),
            },
          },
          TWO_YEAR_PLAN: {
            paypal: state.TWO_YEAR_PLAN.paypal,
            stripe: {
              ...twoYearPlan,
              unit_amount_monthly: (twoYearPlan.unit_amount / 24).toFixed(2),
              discount:
                100 -
                Math.round(
                  (twoYearPlan.unit_amount / 24 / oneMonthPlan.unit_amount) *
                    100
                ),
            },
          },
        }));
      })
      .finally(() => {
        set((state) =>
          state.ONE_MONTH_PLAN.paypal?.id ? { productsLoading: false } : {}
        );
      });

    getProductsPaypalAPI()
      .then((r) => {
        const prices = r.data.pricing?.plans ?? [];

        let oneMonthPlan, oneYearPlan;

        prices.forEach((price) => {
          if (
            price.status === "ACTIVE" &&
            price.billing_cycles.find(
              (cycle) =>
                cycle.tenure_type === "REGULAR" &&
                cycle.frequency.interval_count === 1 &&
                cycle.frequency.interval_unit === "MONTH"
            )
          ) {
            oneMonthPlan = price;
          }

          if (
            price.status === "ACTIVE" &&
            price.billing_cycles.find(
              (cycle) =>
                cycle.tenure_type === "REGULAR" &&
                cycle.frequency.interval_count === 1 &&
                cycle.frequency.interval_unit === "YEAR"
            )
          ) {
            oneYearPlan = price;
          }
        });

        set((state) => ({
          ONE_MONTH_PLAN: {
            stripe: state.ONE_MONTH_PLAN.stripe,
            paypal: oneMonthPlan,
          },
          ONE_YEAR_PLAN: {
            stripe: state.ONE_YEAR_PLAN.stripe,
            paypal: oneYearPlan,
          },
        }));
      })
      .finally(() => {
        set((state) =>
          state.ONE_MONTH_PLAN.stripe?.id ? { productsLoading: false } : {}
        );
      });
  },

  servers: [],
  getServersLoading: false,
  getServers: () => {
    set({ getServersLoading: true });
    getServersAPI()
      .then((resp) => {
        const result = resp.data.flatMap((item) => item.servers);
        set({ servers: result });
      })
      .finally(() => {
        set({ getServersLoading: false });
      });
  },
}));

export { useStore };
