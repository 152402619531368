import "./index.scss";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { isAuthorizedUser } from "../../../utils";

import BurgerBtn from "../../ui/BurgerButton";
import MobileMenu from "../../ui/MobileMenu";

import logoVPN from "../../../assets/logo-vpn4u.svg";

const Header = ({ setShowLoginPopup, isHomePage, isProtectedIp }) => {
  const navigate = useNavigate();

  const isAuthorized = isAuthorizedUser();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToPlans = () => {
    const getPlanEl = document.getElementById("prices");
    getPlanEl &&
      getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToDownload = () => {
    const downloadEl = document.getElementById("download");
    downloadEl &&
      downloadEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <header className="header">
      <div className="header_container">
        <div className="header__logo" onClick={() => navigate("/")}>
          <img src={logoVPN} alt="logo" />
        </div>
        <nav style={isHomePage ? { marginLeft: "auto" } : {}}>
          <ul className="navList">
            <li>
              <a href="/#about">About VPN4U</a>
            </li>
            <li>
              <a href="/#prices">Prices</a>
            </li>
            <li>
              <a href="/#download">Download VPN</a>
            </li>
            <li>
              <a href="/#reviews">Reviews</a>
            </li>
            {/*
            <li>
              <a href="/#faq">FAQ</a>
            </li>
            */}
          </ul>
        </nav>
        {isHomePage && (
          <button
            className="secondary-button get-started"
            style={{ marginLeft: "auto", marginRight: "20px" }}
            onClick={() =>
              isProtectedIp ? scrollToDownload() : scrollToPlans()
            }
          >
            Get started
          </button>
        )}
        <button
          className="default-button alt"
          onClick={() => {
            if (!isAuthorized) {
              setShowLoginPopup({ show: true, mode: "login" });
            } else {
              navigate("/profile");
            }
          }}
        >
          {isAuthorized ? "Profile" : "Login"}
        </button>

        <MobileMenu
          open={isMobileMenuOpen}
          setOpen={setIsMobileMenuOpen}
          scrollToPlans={scrollToPlans}
          setShowLoginPopup={setShowLoginPopup}
        />
        <BurgerBtn
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
      </div>
    </header>
  );
};

export default Header;
