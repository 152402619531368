import "./index.scss";
import React from "react";

import ConfirmIcon from "../../../../assets/confirm-mark.svg";

const ConfirmRegisterInfo = () => (
  <div>
    <h2 className="base-modal__title">Email confirmation</h2>
    <img src={ConfirmIcon} alt="confirm" className="confirm-register__img" />
    <p>
      We have sent you a confirmation email. Please check your mail and follow
      the link in the letter.
    </p>
    <p className="confirm-register__text t-18">
      If you didn't receive a confirmation email:
      <ul>
        <li>Double-check the email you provided</li>
        <li>Take a look in your spam folder</li>
        <li>
          Try to <span className="link">resend it</span>
        </li>
      </ul>
    </p>
  </div>
);

export default ConfirmRegisterInfo;
