import "./index.scss";

import React, { useState } from "react";

import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoaderV2 } from "../Loader";
import BaseModal from "../BaseModal";
import Button from "../Button";

import { changePassword } from "../../../api";

const ChangePasswordPopup = ({ showResetPassPopup, close }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassword = (e) => {
    e.preventDefault();

    const password = e.target.password.value;
    const newPassword = e.target.newPassword.value;
    const newPasswordConfirm = e.target.passwordNewConfirm.value;

    const data = { password, newPassword, newPasswordConfirm };

    setIsLoading(true);

    changePassword(data)
      .then(() => {
        enqueueSnackbar("You have successfully changed password!", {
          variant: "success",
        });
        close();
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BaseModal close={close}>
      <div>
        {isLoading && <LoaderV2 />}

        <form onSubmit={handleChangePassword}>
          <h2 className="base-modal__title change-pass__title">
            Change Password
          </h2>

          <TextField
            required
            name="password"
            type="password"
            id="outlined-basic2"
            label="Your old password"
            variant="outlined"
            className="text-field"
          />
          <p
            className="mb-40 extra-bold pointer"
            onClick={() => {
              showResetPassPopup();
              close();
            }}
          >
            Forgot password?
          </p>
          <TextField
            required
            name="newPassword"
            type="password"
            id="outlined-basic3"
            label="Your new password"
            variant="outlined"
            className="text-field"
          />
          <TextField
            required
            name="passwordNewConfirm"
            type="password"
            id="outlined-basic4"
            label="Confirm new password"
            variant="outlined"
            className="text-field"
          />

          <Button mode="secondary" type="submit" fullWidth className="mt-20">
            Confirm
          </Button>
        </form>
      </div>
    </BaseModal>
  );
};

export default ChangePasswordPopup;
