const PROFILE_TABS = [
  { title: "Download App", name: "download" },
  { title: "Available plans", name: "plans" },
  { title: "Billing History", name: "billing" },
  { title: "Subscriptions", name: "subscriptions" },
  //  { title: "Reports", name: "reports" },
  //  { title: "Referral", name: "referral" },
  { title: "Settings", name: "settings" },
];

export { PROFILE_TABS };
