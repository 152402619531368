import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { gsap } from "gsap";

import { useStore } from "../../../store";

import {
  getRem,
  isDesktopMedia,
  isMobileMedia,
  isTabletMedia,
} from "../../../utils";

import mobile1 from "../../../assets/iphone1.png";
import mobile2 from "../../../assets/iphone2.png";
import { Loader } from "../../ui/Loader";

const HeroSection = ({ isProtectedIp }) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();
  const isDesktop = isDesktopMedia();
  const listRef = useRef(null);
  const priceRef = useRef(null);
  const btnBoxRef = useRef(null);

  const animationBlockRef = useRef(null);
  const mobile2Ref = useRef(null);
  const mobile1Ref = useRef(null);

  const [isActivating, setIsActivating] = useState(false);

  const { ipInfo, getIpInfoLoading, getServersLoading } = useStore();

  const loading = getIpInfoLoading || getServersLoading;

  const scrollToPlans = () => {
    const getPlanEl = document.getElementById("prices");
    getPlanEl &&
      getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToDownload = () => {
    const downloadEl = document.getElementById("download");
    downloadEl &&
      downloadEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    const slides = listRef.current.children;
    const list = listRef.current;
    const duration = 8;
    const lineHeight = listRef.current.children[0].clientHeight;

    const tl = gsap.timeline({
      paused: true,
      repeat: -1,
    });

    const tl1 = gsap.timeline();

    Array.from(slides).forEach((slide, i) => {
      tl.to(list, duration / slides.length, {
        y: i * -1 * lineHeight,
        ease: "elastic.out(1, 1)",
      });
    });

    tl.play();

    if (isMobile) {
      tl1
        .to(priceRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(btnBoxRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(animationBlockRef.current, {
          opacity: 1,
          top: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .to(mobile1Ref.current, {
          duration: 1,
          top: getRem(20),
          left: getRem(20),
          width: getRem(166),
          height: getRem(333),
          ease: "power2.inOut",
        })
        .to(
          mobile2Ref.current,
          {
            duration: 1,
            top: getRem(20),
            right: getRem(20),
            width: getRem(166),
            height: getRem(333),
            ease: "power2.inOut",
          },
          "<"
        );
    } else if (isTablet) {
      tl1
        .to(priceRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(btnBoxRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(animationBlockRef.current, {
          opacity: 1,
          left: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .to(mobile1Ref.current, {
          duration: 1,
          top: getRem(25),
          left: getRem(18),
          width: getRem(180),
          height: getRem(360),
          ease: "power2.inOut",
        })
        .to(
          mobile2Ref.current,
          {
            duration: 1,
            top: getRem(25),
            right: getRem(18),
            width: getRem(180),
            height: getRem(360),
            ease: "power2.inOut",
          },
          "<"
        );
    } else if (isDesktop) {
      tl1
        .to(priceRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(btnBoxRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to(animationBlockRef.current, {
          opacity: 1,
          left: 0,
          duration: 1,
          ease: "power2.inOut",
        })
        .to(mobile1Ref.current, {
          duration: 1,
          top: getRem(30),
          left: getRem(25),
          width: getRem(240),
          height: getRem(480),
          ease: "power2.inOut",
        })
        .to(
          mobile2Ref.current,
          {
            duration: 1,
            top: getRem(30),
            right: getRem(25),
            width: getRem(240),
            height: getRem(480),
            ease: "power2.inOut",
          },
          "<"
        );
    }

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <section className="hero_section" id="home">
      <div className="hero_textContent">
        <div className="hero_headingBox">
          <h1>
            Explore Digital World <br />
            <span>with VPN4U</span>
          </h1>
          <div className="v-slider-frame">
            <ul className="v-slides" ref={listRef}>
              <li className="v-slide">Safely</li>
              <li className="v-slide">Easily</li>
              <li className="v-slide">Freely</li>
              <li className="v-slide">Safely</li>
            </ul>
          </div>
        </div>

        <div className="hero_ipCard" ref={priceRef}>
          <p>
            Your current IP:{" "}
            <span>
              {isProtectedIp || loading ? "---.--.--.---" : ipInfo.ip}
            </span>
          </p>
          <p>
            ISP: <span>{isProtectedIp || loading ? "-" : ipInfo.org}</span>
          </p>
          <p>
            Your status:{" "}
            <span style={{ display: "inline-flex", alignItems: "center" }}>
              <span
                className={`hero_ipStatus ${
                  isProtectedIp
                    ? "protected"
                    : isActivating || loading
                    ? "activating"
                    : ""
                }`}
              >
                {loading
                  ? "Connecting"
                  : isProtectedIp
                  ? "Protected"
                  : isActivating
                  ? "Activating..."
                  : "Unprotected"}
              </span>
              {!isProtectedIp && isActivating && (
                <Loader
                  color="#E9EE00"
                  secondaryColor="#101010"
                  cls="hero_loader"
                />
              )}
            </span>
          </p>
        </div>

        <div className="hero_btnBox" ref={btnBoxRef}>
          <button
            className="secondary-button hero_btnBox_btn"
            onClick={() => {
              if (isProtectedIp) {
                scrollToDownload();
              } else if (!isActivating) {
                setIsActivating(true);
                setTimeout(scrollToPlans, 1500);
              } else {
                scrollToPlans();
              }
            }}
          >
            {isProtectedIp
              ? "Get started"
              : !isActivating
              ? "Get protected"
              : "Get started"}
          </button>
        </div>
      </div>
      <div className="hero_animationBlock" ref={animationBlockRef}>
        <img
          src={mobile2}
          alt="mobile2"
          ref={mobile2Ref}
          className="hero_mobile2"
        />
        <img
          src={mobile1}
          alt="mobile1"
          ref={mobile1Ref}
          className="hero_mobile1"
        />
      </div>
    </section>
  );
};

export default HeroSection;
