import "./index.scss";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import { LoaderV2 } from "../Loader";
import BaseModal from "../BaseModal";
import Button from "../Button";

import { logOut } from "../../../api";

const LogoutPopup = ({ close }) => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogOut = () => {
    setIsLoading(true);
    logOut()
      .then(() => {
        enqueueSnackbar("You have successfully logged out!", {
          variant: "success",
        });
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BaseModal close={close}>
      <div>
        {isLoading && <LoaderV2 />}

        <div>
          <h2 className="base-modal__title">Log out</h2>
          <p className="mb-40 mt-20">
            Are you sure you want to log out of your account? You can log in at
            any time.
          </p>

          <div className="flex-center gap-10">
            <Button mode="primary" onClick={close} fullWidth>
              Cancel
            </Button>
            <Button mode="secondary" onClick={handleLogOut} fullWidth>
              Log Out
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default LogoutPopup;
