import React from "react";

const PROFILE_REPORTS_DATA = [
  {
    date: "14 Feb  2024, 10:11",
    desc: "We fixed minor bugs, added new servers, and made VPN4.ai even better. We fixed minor bugs, added new servers, and made VPN4.ai even better.",
  },
];

const ReportsTab = () => {
  return (
    <div className="profile-reports">
      <div className="profile-reports__box">
        {PROFILE_REPORTS_DATA.map((item, i) => (
          <div className="profile-reports__item" key={i}>
            <p className="profile-reports__date">{item.date}</p>
            <p className="profile-reports__desc">{item.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsTab;
