import "./index.scss";

import React from "react";

const Button = ({ mode, fullWidth, ext, children, className, ...props }) => {
  return (
    <button
      className={`button ${className ?? ""} ${mode} ${
        fullWidth ? "full-width" : ""
      } ${ext ? "ext" : ""}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
