import React from "react";

import { format } from "date-fns";

import { useStore } from "../../../store";

import { LoaderV2 } from "../../ui/Loader";

const BillingTab = () => {
  const { paymentHistory, getPaymentHistoryLoading: isLoading } = useStore();

  const formattedDate = (date) => format(new Date(date), "dd MMM yyyy, HH:mm");

  const isEmpty = () => !paymentHistory?.length || isLoading;

  return (
    <div className="profile-billing">
      <h2>Billing History</h2>
      <div
        className="profile-billing__box"
        style={isEmpty() ? { border: "none" } : {}}
      >
        {isLoading ? (
          <LoaderV2 />
        ) : paymentHistory?.length ? (
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory.map((item, i) => (
                <tr key={i}>
                  <td>VPN4U.IO</td>
                  <td>{item.amount.toFixed(2)}$</td>
                  <td>{formattedDate(item.transaction_date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="profile-billing__empty">There are no billing history</p>
        )}
      </div>
    </div>
  );
};

export default BillingTab;
