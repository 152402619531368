import "./index.scss";

import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import LoginPopup from "../ui/LoginPopup";
import ResetPassPopup from "../ui/ResetPassPopup";

import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer";

const PrivacyPolicyPage = () => {
  const [showLoginPopup, setShowLoginPopup] = useState({
    show: false,
    mode: "login",
    referralCode: "",
  });
  const [resetPassPopup, setResetPassPopup] = useState({
    show: false,
    mode: "initial",
    code: "",
  });

  return (
    <div className="privacy-policy-wrapper">
      <Header setShowLoginPopup={setShowLoginPopup} />
      <div className="privacy-policy">
        <h1>Privacy Policy of VPN4U by Quanta Tech Systems LLC</h1>

        <h3>1. Introduction</h3>

        <p>
          Quanta Tech Systems LLC ("Quanta Tech") respects the privacy of its
          users ("User," "you," or "your") who use our VPN services, VPN4U. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you visit our mobile application and use our
          services. If you do not agree with the terms of this policy, please do
          not access the app or use our services.
        </p>
        <br />

        <h3>2. Information We Collect</h3>

        <h4>a. Personal Data</h4>
        <p>
          We collect personal data that you voluntarily provide to us when
          registering to use our services. This includes:
        </p>
        <ul>
          <li>Your email address and contact information.</li>
          <li>
            Payment details (processed by a third-party payment processor).
          </li>
        </ul>

        <h4>b. Usage Data</h4>
        <p>
          We automatically collect information when you use the service, such
          as:
        </p>
        <ul>
          <li>Connection times.</li>
          <li>The amount of data transmitted.</li>
          <li>VPN server locations used.</li>
        </ul>

        <p>
          <i>Note:</i> VPN4U does not collect logs of your activity, including
          no logging of browsing history, traffic destination, data content, or
          DNS queries.
        </p>
        <br />
        <h3>3. Use of Your Information</h3>
        <p>We use the information we collect about you to:</p>
        <ul>
          <li>Provide, operate, and maintain our services.</li>
          <li>Improve, personalize, and expand our services.</li>
          <li>Understand and analyze how you use our services.</li>
          <li>Develop new products, services, features, and functionality.</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the service, and for
            marketing and promotional purposes.
          </li>
        </ul>
        <br />
        <h3>4. Sharing Your Information</h3>

        <p>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>

        <p>
          Third-Party Service Providers. We may share your information with
          third parties that perform services for us or on our behalf, including
          payment processing, data analysis, email delivery, hosting services,
          customer service, and marketing assistance.
        </p>
        <br />
        <h3>5. Security of Your Information</h3>

        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the information you provide to us, please be aware
          that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse.
        </p>
        <br />
        <h3>6. Policy for Children</h3>

        <p>
          We do not knowingly solicit information from or market to children
          under the age of 13. If we learn that personal information from users
          less than 13 years of age has been collected, we will deactivate the
          account and take reasonable measures to promptly delete such data from
          our records.
        </p>
        <br />
        <h3>7. Changes to This Privacy Policy</h3>

        <p>
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>
        <br />
        <h3>8. Contact Us</h3>

        <p>
          If you have questions or comments about this Privacy Policy, please
          contact us at:
        </p>
        <p>
          Email: <a href="mailto:support@qtech.com">support@qtech.com</a>
        </p>
        <p>Address: 560 W 43rd 41B, New York, NY USA 10036</p>
      </div>
      <Modal
        open={showLoginPopup.show}
        onClose={() => setShowLoginPopup({ show: false, mode: "register" })}
      >
        <LoginPopup
          close={() => setShowLoginPopup({ show: false, mode: "register" })}
          showResetPassPopup={() =>
            setResetPassPopup({ show: true, mode: "initial", code: "" })
          }
          currentMode={showLoginPopup.mode}
          onSuccess={showLoginPopup?.onSuccess}
        />
      </Modal>
      <Modal
        open={resetPassPopup.show}
        onClose={() =>
          setResetPassPopup({ show: false, mode: "initial", code: "" })
        }
      >
        <ResetPassPopup
          close={() =>
            setResetPassPopup({ show: false, mode: "initial", code: "" })
          }
          currentMode={resetPassPopup.mode}
          code={resetPassPopup.code}
        />
      </Modal>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
