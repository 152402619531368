import "./index.scss";

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { useStore } from "../../../../store";

import PaymentMethodPopup from "../../../ui/PaymentMethodPopup";
import Button from "../../../ui/Button";

import Days30Icon from "../../../../assets/plan/30-days.svg";

import { openInNewTab, requiredAuth } from "../../../../utils";

import { subscribePaypal, subscribeStripe } from "../../../../api";

const PlanTable = ({ isHomePage, authorizeRequest }) => {
  const plansContainerRef = useRef(null);
  const firstPlanRef = useRef(null);
  const lastPlanRef = useRef(null);

  const [paymentMethodPopup, setPaymentMethodPopup] = useState({
    show: false,
    selectedPlanId: "",
    payViaStripe: () => null,
    payViaPaypal: () => null,
  });

  const { getProductsLoading, ONE_MONTH_PLAN, ONE_YEAR_PLAN, TWO_YEAR_PLAN } =
    useStore();

  const handleSelectPlan = (selectedPlan) => {
    if (getProductsLoading) {
      return;
    }

    const payViaStripe = () => {
      subscribeStripe({ plan: selectedPlan.stripe.id }).then((response) => {
        const checkoutUrl = response.data.checkout_url;
        openInNewTab(checkoutUrl);
      });
    };

    const payViaPaypal = () => {
      subscribePaypal({ plan: selectedPlan.paypal.id }).then((response) => {
        const checkoutUrl = response.data.redirect_url;
        openInNewTab(checkoutUrl);
      });
    };

    setPaymentMethodPopup({
      show: true,
      selectedPlanId: selectedPlan.stripe.id,
      payViaStripe: requiredAuth(payViaStripe, authorizeRequest),
      payViaPaypal: requiredAuth(payViaPaypal, authorizeRequest),
    });
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: plansContainerRef.current,
        start: "top 40%",
      },
    });

    if (isHomePage) {
      tl.fromTo(
        plansContainerRef.current,
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: "power2.inOut" }
      )
        .fromTo(
          firstPlanRef.current,
          {
            transform: "translate(-50%, 0)",
          },
          {
            opacity: 1,
            duration: 1,
            transform: "translate(0, 0)",
            ease: "power2.inOut",
          },
          "<"
        )
        .fromTo(
          lastPlanRef.current,
          {
            transform: "translate(50%, 0)",
          },
          {
            duration: 1,
            transform: "translate(0, 0)",
            ease: "power2.inOut",
          },
          "<"
        );
    }

    return () => {
      tl.kill();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={isHomePage ? "plans-home" : ""} id="prices">
      <h2 className="plans__title">Get your optimal plan</h2>
      <p className="plans__main-lbl">
        and protect your privacy & digital life from <span>$0.07</span> per day!
      </p>
      <div className="plans" ref={plansContainerRef}>
        <div className="plans__item" ref={firstPlanRef}>
          <div>
            <p className="plans__period">1 Month</p>
          </div>
          <div>
            <h2 className="plans__curr-price">
              ${ONE_MONTH_PLAN.stripe.unit_amount}
            </h2>
            <p className="plans__lbl">per month</p>
          </div>
          <div className="plans__item-btm">
            <Button
              mode="secondary"
              className="t_get-1-month-btn"
              onClick={() => handleSelectPlan(ONE_MONTH_PLAN)}
              fullWidth
            >
              Get 1 month for ${ONE_MONTH_PLAN.stripe.unit_amount}
            </Button>
            <p className="plan-table__head-money-back">
              <img src={Days30Icon} alt="30 days" />
              <span>30-day money-back guarantee</span>
            </p>
          </div>
        </div>

        <div className="plans__ben">
          <div className="plans__ben-top">
            <p>The most beneficial</p>
          </div>
          <div className="plans__item">
            <div>
              <p className="plans__period">24 Months</p>
              <p className="plans__save">
                SAVE {TWO_YEAR_PLAN.stripe.discount}%
              </p>
            </div>
            <div>
              <p className="plans__old-price">
                ${ONE_MONTH_PLAN.stripe.unit_amount}
              </p>
              <h2 className="plans__curr-price">
                ${TWO_YEAR_PLAN.stripe.unit_amount_monthly}
              </h2>
              <p className="plans__lbl">per month</p>
            </div>
            <div className="plans__item-btm">
              <Button
                mode="secondary"
                className="t_get-24-months-btn"
                onClick={() => handleSelectPlan(TWO_YEAR_PLAN)}
                fullWidth
              >
                Get 24 months for ${TWO_YEAR_PLAN.stripe.unit_amount}
              </Button>
              <p className="plan-table__head-money-back">
                <img src={Days30Icon} alt="30 days" />
                <span>30-day money-back guarantee</span>
              </p>
            </div>
          </div>
        </div>

        <div className="plans__item" ref={lastPlanRef}>
          <div>
            <p className="plans__period">12 Months</p>
            <p className="plans__save">SAVE {ONE_YEAR_PLAN.stripe.discount}%</p>
          </div>
          <div>
            <p className="plans__old-price">
              ${ONE_MONTH_PLAN.stripe.unit_amount}
            </p>
            <h2 className="plans__curr-price">
              ${ONE_YEAR_PLAN.stripe.unit_amount_monthly}
            </h2>
            <p className="plans__lbl">per month</p>
          </div>
          <div className="plans__item-btm">
            <Button
              mode="secondary"
              className="t_get-12-months-btn"
              onClick={() => handleSelectPlan(ONE_YEAR_PLAN)}
              fullWidth
            >
              Get 12 months for ${ONE_YEAR_PLAN.stripe.unit_amount}
            </Button>
            <p className="plan-table__head-money-back">
              <img src={Days30Icon} alt="30 days" />
              <span>30-day money-back guarantee</span>
            </p>
          </div>
        </div>
      </div>
      {/*isHomePage && (
        <p className="plans-home__any-questions">
          Do you have any questions? Write <a href=" ">directly here</a> .
        </p>
      )*/}

      <Modal
        open={paymentMethodPopup.show}
        onClose={() => setPaymentMethodPopup({ show: false })}
      >
        <PaymentMethodPopup
          close={() => setPaymentMethodPopup({ show: false })}
          selectedPlanId={paymentMethodPopup.selectedPlanId}
          payViaStripe={paymentMethodPopup.payViaStripe}
          payViaPaypal={paymentMethodPopup.payViaPaypal}
        />
      </Modal>
    </div>
  );
};

export default PlanTable;
