import "./index.scss";

import React, { useState } from "react";

import { useStore } from "../../../store";

import BaseModal from "../BaseModal";
import Button from "../Button";
import RadioButton from "../RadioButton";

import StripeImg from "../../../assets/payment-systems/stripe.svg";
import PaypalImg from "../../../assets/payment-systems/paypal.svg";
//import CPayImg from "../../../assets/payment-systems/cpay.svg";

const PAYMENT_METHODS = {
  stripe: "stripe",
  paypal: "paypal",
  cpay: "cpay",
};

const PaymentMethodPopup = ({
  close,
  freeTrial,
  currPrice,
  selectedPlanId,
  payViaStripe,
  payViaPaypal,
}) => {
  const [selectedPM, setSelectedPM] = useState(PAYMENT_METHODS.stripe);

  const { TWO_YEAR_PLAN } = useStore();

  const isShowPaypal =
    selectedPlanId !== TWO_YEAR_PLAN.stripe?.id && payViaPaypal;

  const handlePay = () => {
    switch (selectedPM) {
      case PAYMENT_METHODS.stripe:
        payViaStripe();
        close();
        break;
      case PAYMENT_METHODS.paypal:
        payViaPaypal();
        close();
        break;
      default:
        break;
    }
  };

  return (
    <BaseModal close={close}>
      <div
        className={`select-pay-popup ${
          freeTrial ? "select-pay-popup_free-trial" : ""
        }`}
      >
        <h2>Select a payment method</h2>
        {freeTrial && (
          <p className="select-pay-popup__subtitle">
            WE WILL NOT CHARGE YOU NOW
          </p>
        )}
        <div className="select-pay-popup__box">
          <label className="select-pay-popup__item">
            <RadioButton
              name="select-payment"
              checked={selectedPM === PAYMENT_METHODS.stripe}
              onChange={() => setSelectedPM(PAYMENT_METHODS.stripe)}
            />
            <p className="select-pay-popup__item-label">Pay with Stripe</p>
            <img
              src={StripeImg}
              className="select-pay-popup__item-img"
              alt="stripe"
            />
          </label>
          {/** PAYPAL DOESN'T SUPPORT 2-YEAR SUBSCRIPTION */}
          {isShowPaypal && (
            <label className="select-pay-popup__item">
              <RadioButton
                name="select-payment"
                checked={selectedPM === PAYMENT_METHODS.paypal}
                onChange={() => setSelectedPM(PAYMENT_METHODS.paypal)}
              />
              <p className="select-pay-popup__item-label">Pay with PayPal</p>
              <img
                src={PaypalImg}
                className="select-pay-popup__item-img"
                alt="stripe"
              />
            </label>
          )}
          {/*<label className="select-pay-popup__item">
            <RadioButton
              name="select-payment"
              checked={selectedPayment === PAYMENT_METHODS.cpay}
              onChange={() => setSelectedPayment(PAYMENT_METHODS.cpay)}
            />
            <p className="select-pay-popup__item-label">Pay with cPay</p>
            <img
              src={CPayImg}
              className="select-pay-popup__item-img"
              alt="stripe"
            />
            </label>*/}
        </div>
        {!freeTrial ? (
          <Button mode="secondary" type="button" fullWidth onClick={handlePay}>
            Go to payment
          </Button>
        ) : (
          <div className="select-pay-popup__btm">
            <div className="select-pay-popup__now">
              <p>Total now</p>
              <p>
                <span>$0.00</span>
              </p>
            </div>
            <div className="select-pay-popup__future">
              <p>Total on Avg 16</p>
              <p>
                <span>{currPrice}</span>
              </p>
            </div>
            <Button
              mode="secondary"
              type="button"
              fullWidth
              onClick={handlePay}
            >
              Go to payment
            </Button>
            <p className="select-pay-popup__btm-lbl">
              You can always cancel your subscription
              <br /> and get a refund within 14 days after payment.
            </p>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default PaymentMethodPopup;
