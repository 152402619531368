import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";

import { useStore } from "../../store";

import { emailVerify, resetPasswordCheck } from "../../api";

import { isAuthorizedUser } from "../../utils";

import Header from "./Header";
import HeroSection from "./HeroSection";
import AdvantagesSection from "./Advantages";
import AboutSection from "./About";
import FeaturesSection from "./Features";
import WeOfferSection from "./WeOffer";
import PlanTable from "../ProfilePage/PlansTab/PlanTable";
import CustomersSection from "./Customers";
//import FAQSection from "./FAQ";
import Footer from "./Footer";

import LoginPopup from "../ui/LoginPopup";

import ResetPassPopup from "../ui/ResetPassPopup";
//import FreeTrialPopup from "../ui/FreeTrialPopup";
import SuccessPaymentPopup from "../ui/SuccessPaymentPopup";

const HomePage = () => {
  const navigate = useNavigate();

  const [showLoginPopup, setShowLoginPopup] = useState({
    show: false,
    mode: "register",
    referralCode: "",
  });
  const [resetPassPopup, setResetPassPopup] = useState({
    show: false,
    mode: "initial",
    code: "",
  });
  const [showSuccessPaymentPopup, setShowSuccessPaymentPopup] = useState(false);
  const [showTrialPopup, setShowTrialPopup] = useState(false);
  const [getPlanEl, setGetPlanEl] = useState(null);

  const { ipInfo, getIpInfo, getProducts, servers, getServers } = useStore();

  const { enqueueSnackbar } = useSnackbar();

  const isAuthorized = isAuthorizedUser();

  const searchParams = new URLSearchParams(window.location.search);
  const confirmEmailCode = searchParams.get("email-code");
  const resetPassCode = searchParams.get("reset-pass-code");
  const referralCode = searchParams.get("referral-code");
  const errorMessage = searchParams.get("error-message");
  const showSuccessfulPayment = searchParams.get("show-successful-payment");
  const fromApp = searchParams.get("from_app");

  const isProtectedIp = servers.map((server) => server.ip).includes(ipInfo.ip);

  const scrollToPlans = () => {
    getPlanEl &&
      getPlanEl.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleConfirmEmail = (code) => {
    emailVerify({ code })
      .then(() => {
        enqueueSnackbar("You have successfully verified email!", {
          variant: "success",
        });
        setShowLoginPopup({ show: true, mode: "login" });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        navigate("/");
      });
  };

  const handleResetPass = (code) => {
    resetPasswordCheck({ code })
      .then(() => {
        setResetPassPopup({ show: true, mode: "new-pass", code });
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      })
      .finally(() => {
        navigate("/");
      });
  };

  const handleRegisterWithRefCode = (referralCode) => {
    setShowLoginPopup({
      show: true,
      mode: "register",
      referralCode,
    });
  };

  useEffect(() => {
    const foundEl = document.getElementById("get-plan");
    setGetPlanEl(foundEl);
  }, []);

  useEffect(() => {
    confirmEmailCode && handleConfirmEmail(confirmEmailCode);
    // eslint-disable-next-line
  }, [confirmEmailCode]);

  useEffect(() => {
    resetPassCode && handleResetPass(resetPassCode);
    // eslint-disable-next-line
  }, [resetPassCode]);

  useEffect(() => {
    referralCode && handleRegisterWithRefCode(referralCode);
  }, [referralCode]);

  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: "error" });
      navigate("/");
    }
    // eslint-disable-next-line
  }, [errorMessage]);

  useEffect(() => {
    if (showSuccessfulPayment) {
      setShowSuccessPaymentPopup(true);
      navigate("/");
    }
    // eslint-disable-next-line
  }, [showSuccessfulPayment]);

  useEffect(() => {
    if (fromApp) {
      if (!isAuthorized) {
        setShowLoginPopup({
          show: true,
          mode: "login",
          onSuccess: scrollToPlans,
        });
      } else {
        scrollToPlans();
      }
    }
    // eslint-disable-next-line
  }, [fromApp]);

  useEffect(() => {
    getIpInfo();
    getProducts();
    getServers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        setShowLoginPopup={setShowLoginPopup}
        isHomePage
        isProtectedIp={isProtectedIp}
      />
      <HeroSection isProtectedIp={isProtectedIp} />
      <AdvantagesSection />
      <PlanTable
        isHomePage={true}
        showSuccessPayment={() => setShowSuccessPaymentPopup(true)}
        authorizeRequest={({ onSuccess }) =>
          setShowLoginPopup({
            show: true,
            mode: "register",
            onSuccess: onSuccess,
          })
        }
      />
      <AboutSection />
      <FeaturesSection />
      <WeOfferSection setShowTrialPopup={setShowTrialPopup} />
      <CustomersSection setShowTrialPopup={setShowTrialPopup} />
      {/*<FAQSection />*/}
      <Footer isHomePage isProtectedIp={isProtectedIp} />

      <Modal
        open={showLoginPopup.show}
        onClose={() => setShowLoginPopup({ show: false, mode: "register" })}
      >
        <LoginPopup
          close={() => setShowLoginPopup({ show: false, mode: "register" })}
          showResetPassPopup={() =>
            setResetPassPopup({ show: true, mode: "initial", code: "" })
          }
          currentMode={showLoginPopup.mode}
          //          referralCode={referralCode}
          onSuccess={showLoginPopup?.onSuccess}
          scrollToPlans={scrollToPlans}
        />
      </Modal>
      <Modal
        open={resetPassPopup.show}
        onClose={() =>
          setResetPassPopup({ show: false, mode: "initial", code: "" })
        }
      >
        <ResetPassPopup
          close={() =>
            setResetPassPopup({ show: false, mode: "initial", code: "" })
          }
          currentMode={resetPassPopup.mode}
          code={resetPassPopup.code}
        />
      </Modal>
      <Modal
        open={showSuccessPaymentPopup}
        onClose={() => setShowSuccessPaymentPopup(false)}
      >
        <SuccessPaymentPopup close={() => setShowSuccessPaymentPopup(false)} />
      </Modal>
      {/*
      <Modal open={showTrialPopup} onClose={() => setShowTrialPopup(false)}>
        <FreeTrialPopup
          close={() => setShowTrialPopup(false)}
          showSuccessPayment={() => setShowSuccessPaymentPopup(true)}
          authorizeRequest={({ onSuccess }) =>
            setShowLoginPopup({
              show: true,
              mode: "register",
              onSuccess: onSuccess,
            })
          }
        />
        </Modal>*/}
    </>
  );
};

export default HomePage;
