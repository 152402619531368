import React from "react";

import { useStore } from "../../../store";

import { copyToClipboard } from "../../../utils";

import ReferralBenefits from "./ReferralBenefits";

const ReferralTab = () => {
  const { user } = useStore();

  const domain = window.location.host;
  const referralLink = `https://${domain}/?referral-code=${user.referralCode}`;

  return (
    <div className="profile-referral">
      <ReferralBenefits />
      <div className="profile-referral__box">
        <div className="profile-referral__item">
          <p>
            You will receive 10% of all payments made by your referrals who
            register using your unique link and code. Share your referral link
            and referral code with your friends and community!{" "}
          </p>
          <p>
            Your unique referral link: <span>{referralLink}</span>
            <button
              className="default-button alt profile-referral__btn"
              onClick={() =>
                copyToClipboard(
                  referralLink,
                  "Referral link successfully copied!"
                )
              }
            >
              Copy
            </button>
          </p>
          <p>
            Your unique referral code: <span>{user.referralCode}</span>
            <button
              className="default-button alt profile-referral__btn"
              onClick={() =>
                copyToClipboard(
                  user.referralCode,
                  "Referral code successfully copied!"
                )
              }
            >
              Copy
            </button>
          </p>
        </div>
        <div className="profile-referral__item">
          <h3>Your statistic</h3>
          <div className="profile-referral__stats">
            <p>
              The number of your referrals: <span>{"0"}</span>
            </p>
            <p>
              Total income from referrals: <span>{"$0"}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralTab;
