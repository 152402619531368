import React, { useEffect, useRef } from "react";
import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import map from "../../../assets/worldMap.png";
import smith from "../../../assets/customers/smith.png";
import klein from "../../../assets/customers/klein.png";
import lewis from "../../../assets/customers/lewis.png";
import roberts from "../../../assets/customers/roberts.png";
import thompson from "../../../assets/customers/thompson.png";
import Slider from "react-slick";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import {
  getRem,
  isDesktopMedia,
  isMobileMedia,
  isTabletMedia,
} from "../../../utils";

gsap.registerPlugin(ScrollTrigger);

const feedbacksData = [
  {
    id: 1,
    img: smith,
    name: "John Smith",
    profession: "Front-end Developer",
    feedback:
      "Been using this VPN for months, and the interface is very user-friendly. Setup was easy, and the cost is low. Highly recommended!",
  },
  {
    id: 2,
    img: thompson,
    name: "Sarah Thompson",
    profession: "Student",
    feedback:
      "No downtime, and my data is secure. One account works on all my devices. ",
  },
  {
    id: 3,
    img: roberts,
    name: "Michael Roberts",
    profession: "Marketer",
    feedback:
      "This VPN is a lifesaver! Low cost and easy-to-use interface. Works perfectly on my phone, tablet, and laptop. Worth every penny!",
  },
  {
    id: 4,
    img: klein,
    name: "Emily Klein",
    profession: "Copywriter",
    feedback:
      "This VPN stands out for ease of use and affordability. The interface is intuitive.",
  },
  {
    id: 5,
    img: lewis,
    name: "David Lewis",
    profession: "Designer",
    feedback:
      "Fantastic VPN! Straightforward interface and very affordable. Super reliable and convenient!",
  },
];

const CustomSlide = ({ feedbackData }) => {
  const { img, name, profession, feedback } = feedbackData;
  return (
    <div className="feedbacks_card">
      <img src={img} alt={name} />
      <p className="name">{name}</p>
      <p className="profession">{profession}</p>
      <p className="feedback">{feedback}</p>
    </div>
  );
};

const NextArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="arrowNext"
      onClick={onClick}
      style={{
        ...style,
      }}
    />
  );
};
const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className="arrowPrev"
      style={{
        ...style,
      }}
      onClick={onClick}
    />
  );
};

const CustomersSection = ({ setShowTrialPopup }) => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();

  const titleRef = useRef(null);
  const desc1Ref = useRef(null);
  const desc2Ref = useRef(null);
  const btnRef = useRef(null);
  const containerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: titleRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl1
      .to(titleRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(desc1Ref.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
      .to(
        desc2Ref.current,
        {
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        },
        "<"
      )
      .to(btnRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: "power2.inOut",
      });
  }, []);

  useEffect(() => {
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl2.to(
      mapRef.current,
      {
        maskPosition: 0,
        "-webkit-mask-position": 0,
        duration: 2.3,
        ease: "linear",
      },
      "<"
    );
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: isMobile ? 1 : isTablet ? 2.1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <section className="world_section">
        <h3 className="world_title">Our servers around the world</h3>
        <p className="world_subtitle">The whole world is open to you</p>
        <div className="world_wrapper" ref={containerRef}>
          <img src={map} alt="map" className="world_map" ref={mapRef} />
        </div>
      </section>
      <section className="feedbacks_section">
        <h3 className="feedbacks_title">
          Our customer network is growing every day
        </h3>
        <div className="feedbacks_slider" id="reviews">
          <Slider {...settings}>
            {feedbacksData.map((feedback, i) => (
              <CustomSlide index={i} feedbackData={feedback} key={i} />
            ))}
          </Slider>
        </div>
        {/*
        <div className="freeTrial_block">
          <h3 className="freeTrial_title" ref={titleRef}>
            Try our product for free
          </h3>
          <p className="freeTrial_desc" ref={desc1Ref}>
            Still haven't decided on a VPN?
          </p>
          <p className="freeTrial_desc" ref={desc2Ref}>
            Get a 30-day trial!
          </p>
          <button
            className="secondary-button freeTrial_btn"
            ref={btnRef}
            onClick={() => setShowTrialPopup(true)}
          >
            Get Free Trial
          </button>
          </div>*/}
      </section>
    </>
  );
};

export default CustomersSection;
