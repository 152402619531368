import React from "react";

import GooglePlayLogo from "../../../assets/stores/google-play.svg";
//import MicrosoftLogo from "../../../assets/stores/microsoft.svg";
import AppleStoreLogo from "../../../assets/stores/apple.svg";
import ChromeLogo from "../../../assets/stores/chrome.svg";

import QRAndroidImg from "../../../assets/qr-codes/android-qr.svg";
import QrIOSImg from "../../../assets/qr-codes/ios-qr.png";
import QrExtension from "../../../assets/qr-codes/extension.svg";

import { DOWNLOAD_LINKS } from "../../../const/config";

const DownloadTab = () => (
  <div className="profile-download">
    <h2>
      You can download the app and log in with the credentials you entered
      during registration.
    </h2>
    <div className="profile-download__box">
      {/** iOS */}
      <div className="profile-download__item">
        <p>iOS App:</p>
        <div
          className="profile-download__btn t_download-google-play"
          onClick={() => window.open(DOWNLOAD_LINKS.IOS)}
        >
          <img src={AppleStoreLogo} alt="Apple Store" />
          <div>
            <p className="profile-download__btn-label">GET IT ON</p>
            <p className="profile-download__btn-store">App Store</p>
          </div>
        </div>
        <img src={QrIOSImg} alt="iOS" />
      </div>

      {/** ANDROID */}
      <div className="profile-download__item">
        <p>Android App:</p>
        <div
          className="profile-download__btn t_download-google-play"
          onClick={() => window.open(DOWNLOAD_LINKS.ANDROID)}
        >
          <img src={GooglePlayLogo} alt="Google Play" />
          <div>
            <p className="profile-download__btn-label">GET IT ON</p>
            <p className="profile-download__btn-store">Google Play</p>
          </div>
        </div>
        <img src={QRAndroidImg} alt="android" />
      </div>

      {/** DESKTOP 
      <div className="profile-download__item">
        <p>Desktop App:</p>
        <div
          className="profile-download__btn t_download-google-play"
          onClick={() => window.open(DOWNLOAD_LINKS.CHROME_EXT)}
        >
          <img src={MicrosoftLogo} alt="Windows" />
          <div>
            <p className="profile-download__btn-label">Download for</p>
            <p className="profile-download__btn-store">Windows</p>
          </div>
        </div>
      </div>*/}

      {/** CHROME EXTENSION */}
      <div className="profile-download__item">
        <p>Browser Extension:</p>
        <div
          className="profile-download__btn t_download-google-play"
          onClick={() => window.open(DOWNLOAD_LINKS.CHROME_EXT)}
        >
          <img src={ChromeLogo} alt="Chrome" />
          <div>
            <p className="profile-download__btn-label">Download for</p>
            <p className="profile-download__btn-store">Chrome</p>
          </div>
        </div>
        <img src={QrExtension} alt="chrome extension" />
      </div>
    </div>
  </div>
);

export default DownloadTab;
