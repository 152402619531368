import "./index.scss";

import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

import { getRem, isMobileMedia, isTabletMedia } from "../../../utils";

import SpeedBenefitImg from "../../../assets/speed-benefit.svg";

import ben1 from "../../../assets/iconBenefit1.svg";
import ben2 from "../../../assets/iconBenefit2.svg";
import ben3 from "../../../assets/iconBenefit3.svg";
import ben4 from "../../../assets/iconBenefit4.svg";
import Flag1 from "../../../assets/flags/flag-1.png";
import Flag2 from "../../../assets/flags/flag-2.png";
import Flag3 from "../../../assets/flags/flag-3.png";
import Flag4 from "../../../assets/flags/flag-4.png";
import Flag5 from "../../../assets/flags/flag-5.png";
import Flag6 from "../../../assets/flags/flag-6.png";
import Flag7 from "../../../assets/flags/flag-7.png";
import Flag8 from "../../../assets/flags/flag-8.png";
import Flag9 from "../../../assets/flags/flag-9.png";
import Flag10 from "../../../assets/flags/flag-10.png";
import Flag11 from "../../../assets/flags/flag-11.png";
import Flag12 from "../../../assets/flags/flag-12.png";
import Flag13 from "../../../assets/flags/flag-13.png";
import Flag14 from "../../../assets/flags/flag-14.png";
import Flag15 from "../../../assets/flags/flag-15.png";
import Flag16 from "../../../assets/flags/flag-16.png";
import Flag17 from "../../../assets/flags/flag-17.png";
import Flag18 from "../../../assets/flags/flag-18.png";
import Flag19 from "../../../assets/flags/flag-19.png";
import Flag20 from "../../../assets/flags/flag-20.png";
import Flag21 from "../../../assets/flags/flag-21.png";
import Flag22 from "../../../assets/flags/flag-22.png";
import Flag23 from "../../../assets/flags/flag-23.png";
import Flag24 from "../../../assets/flags/flag-24.png";
import Flag25 from "../../../assets/flags/flag-25.png";
import Flag26 from "../../../assets/flags/flag-26.png";
import Flag27 from "../../../assets/flags/flag-27.png";
import Flag28 from "../../../assets/flags/flag-28.png";
import Flag29 from "../../../assets/flags/flag-29.png";
import Flag30 from "../../../assets/flags/flag-30.png";

const FLAGS = [
  Flag26,
  Flag19,
  Flag5,
  Flag25,
  Flag29,
  Flag30,
  Flag4,
  Flag21,
  Flag23,
  Flag22,
  Flag20,
  Flag10,
  Flag9,
  Flag24,
  Flag18,
  Flag12,
  Flag7,
  Flag17,
  Flag8,
  Flag11,
  Flag15,
  Flag27,
  Flag3,
  Flag16,
  Flag6,
  Flag1,
  Flag2,
  Flag14,
  Flag13,
  Flag28,
];

const benefitsData = [
  {
    id: 1,
    icon: ben1,
    digit: "2000+",
    text: "Servers Active",
  },
  {
    id: 2,
    icon: ben2,
    digit: "31+",
    text: "Countries",
  },
  {
    id: 3,
    icon: ben3,
    digit: "100%",
    text: "Money Back",
  },
  {
    id: 4,
    icon: ben4,
    digit: "24/7",
    text: "Support",
  },
];

const AdvantagesSection = () => {
  const isMobile = isMobileMedia();
  const isTablet = isTabletMedia();

  const benefitsListRef = useRef(null);
  const sectionRef = useRef(null);
  const serversRef = useRef(null);
  const speedRef = useRef(null);
  const speedValueRef = useRef(null);
  const speedCircleRef1 = useRef(null);
  const speedCircleRef2 = useRef(null);
  const flagRefs = useRef([]);

  const runSpeedAnim = () => {
    const elements = [...document.querySelectorAll(".speed-box span")];
    const delay = 350;
    const duration = 3000;

    const splitIndex = elements.length - 11;

    const lastElements = elements.slice(splitIndex);
    const restElements = elements.slice(0, splitIndex);

    [...lastElements, ...restElements].forEach((el, i) => {
      setTimeout(() => {
        el.classList.add("active");
      }, (duration / elements.length) * i + delay);
    });

    const speeds = [
      "0 MB/s",
      "56 MB/s",
      "112 MB/s",
      "169 MB/s",
      "226 MB/s",
      "283 MB/s",
      "340 MB/s",
      "397 MB/s",
      "454 MB/s",
      "511 MB/s",
      "568 MB/s",
      "625 MB/s",
      "682 MB/s",
      "739 MB/s",
      "796 MB/s",
      "853 MB/s",
      "910 MB/s",
      "967 MB/s",
      "1024 MB/s",
      "1 GB/s",
    ];

    speeds.forEach((speed, i) => {
      setTimeout(() => {
        if (speedValueRef.current) {
          speedValueRef.current.textContent = speed;
        }
      }, (duration / speeds.length) * i + delay);
    });

    setTimeout(() => {
      if (speedValueRef.current) {
        speedValueRef.current.classList.add("active");
      }
    }, duration + delay);
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const benefitsListItems = benefitsListRef.current.children;

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: benefitsListRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    if (isMobile) {
      tl2
        .to([benefitsListItems[0], benefitsListItems[1]], {
          transform: "translate(0, 0)",
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        })
        .to([benefitsListItems[2], benefitsListItems[3]], {
          transform: "translate(0, 0)",
          opacity: 1,
          duration: 0.5,
          ease: "power2.inOut",
        });
    } else {
      Array.from(benefitsListItems).forEach((item, index) => {
        tl2.to(item, {
          opacity: 1,
          transform: "translate(0, 0)",
          duration: 0.5,
          delay: index * 0.1,
          ease: "power2.inOut",
        });
      });
    }
  }, []);

  useEffect(() => {
    gsap.set(flagRefs.current, { autoAlpha: 0 });

    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: serversRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    if (!isTablet && !isMobile) {
      const sequenceOrder = [
        0, 30, 30, 1, 10, 30, 2, 11, 20, 3, 12, 21, 4, 13, 22, 5, 14, 23, 6, 15,
        24, 7, 16, 25, 8, 17, 26, 9, 18, 27, 19, 28, 30, 29, 30, 30,
      ];

      sequenceOrder.forEach((index, i) => {
        if (i % 3 !== 0) {
          tl3.to(
            flagRefs.current[index],
            {
              autoAlpha: 1,
              duration: 0.15,
            },
            "<"
          );
        } else {
          tl3.to(flagRefs.current[index], {
            autoAlpha: 1,
            duration: 0.15,
            delay: 0,
          });
        }
      });
    } else if (isTablet) {
      const sequenceOrder = [
        0, 30, 30, 30, 1, 8, 30, 30, 2, 9, 16, 30, 3, 10, 17, 30, 4, 11, 18, 24,
        5, 12, 19, 25, 6, 13, 20, 26, 7, 14, 21, 27, 15, 22, 28, 30, 23, 29, 30,
        30,
      ];

      sequenceOrder.forEach((index, i) => {
        if (i % 4 !== 0) {
          tl3.to(
            flagRefs.current[index],
            {
              autoAlpha: 1,
              duration: 0.15,
            },
            "<"
          );
        } else {
          tl3.to(flagRefs.current[index], {
            autoAlpha: 1,
            duration: 0.15,
            delay: 0,
          });
        }
      });
    } else if (isMobile) {
      const sequenceOrderMobile = [...new Array(30)].map((_, i) => i);

      sequenceOrderMobile.forEach((index, i) => {
        if (i % 5 !== 0) {
          tl3.to(
            flagRefs.current[index],
            {
              autoAlpha: 1,
              duration: 0.25,
            },
            "<"
          );
        } else {
          tl3.to(flagRefs.current[index], {
            autoAlpha: 1,
            duration: 0.25,
            delay: 0.15,
          });
        }
      });
    }

    return () => {
      tl3.kill();
    };
  }, []);

  useEffect(() => {
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: speedRef.current,
        start: "top 60%",
        // markers: true,
      },
    });

    tl4
      .to(speedRef.current, {
        onComplete: runSpeedAnim,
      })
      .to(
        speedCircleRef1.current,
        {
          rotate: 1000,
          duration: 5,
        },
        "<"
      )
      .to(
        speedCircleRef2.current,
        {
          rotate: -1090,
          duration: 5,
        },
        "<"
      );
  }, []);

  useEffect(() => {
    const container = document.querySelector(".speed-box");

    const n = 36;

    for (let i = 1; i <= n; i++) {
      const span = document.createElement("span");
      span.textContent = "";
      span.style.transform = `translate(-50%, -50%) rotate(${
        (360 / n) * i
      }deg) translateY(${getRem(80)})`;
      container.appendChild(span);
    }
  }, []);

  return (
    <div className="advantages">
      <section className="advantages-servers" ref={serversRef}>
        <h3 className="advantages-servers__title">
          Discover the digital universe
        </h3>
        <p className="advantages-servers__subtitle">
          Servers from around the world
        </p>
        <div className="advantages-servers__box">
          {FLAGS.map((flag, i) => (
            <img
              src={flag}
              alt="flag"
              key={i}
              ref={(el) => (flagRefs.current[i] = el)}
            />
          ))}
        </div>
      </section>

      <section className="advantages-speed" ref={speedRef}>
        <h3 className="advantages-servers__title">
          Don't limit yourself in possibilities
        </h3>
        <p className="advantages-servers__subtitle">
          Advanced technologies ensure performance
        </p>
        <div className="advantages-speed__container">
          <p className="advantages-speed__title">
            High data
            <br /> transfer speed
          </p>
          <div className="speed-box">
            <div className="advantages-circle-1_" ref={speedCircleRef1} />
            <div className="advantages-circle-2_" ref={speedCircleRef2} />
            <img src={SpeedBenefitImg} alt="speed" />
          </div>
          <p className="advantages-speed__subtitle" ref={speedValueRef}>
            0 MB/s
          </p>
        </div>
      </section>

      <section className="benefits_section" ref={sectionRef}>
        <ul className="benefits_list" ref={benefitsListRef}>
          {benefitsData.map(({ id, icon, digit, text }) => (
            <li className="benefits_listItem" key={id}>
              <img src={icon} alt={`benefit ${id}`} />
              <p className="digit">{digit}</p>
              <p className="text">{text}</p>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default AdvantagesSection;
