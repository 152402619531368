import "./index.scss";

import React, { useEffect, useState } from "react";

import RegisterForm from "./RegisterForm";
import ConfirmRegisterInfo from "./ConfirmRegisterInfo";
import LoginForm from "./LoginForm";
import { LoaderV2 } from "../Loader";

import BaseModal from "../BaseModal";

const LoginPopup = ({
  showResetPassPopup,
  currentMode,
  referralCode,
  close,
  onSuccess,
  scrollToPlans,
}) => {
  /** modes: [confirm-email, register, login ] */
  const [mode, setMode] = useState(currentMode ?? "register");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /** Reset forms after switching between modes */
    if (["login", "register"].includes(mode)) {
      const loginForm = document.getElementById("login-form");
      const registerForm = document.getElementById("register-form");

      loginForm && loginForm.reset();
      registerForm && registerForm.reset();
    }
  }, [mode]);

  return (
    <BaseModal close={close}>
      <div>
        {isLoading && <LoaderV2 />}

        {mode === "register" ? (
          <RegisterForm
            referralCode={referralCode}
            switchToLogin={() => setMode("login")}
            switchToConfirmEmail={() => setMode("confirm-email")}
            setIsLoading={setIsLoading}
            onSuccess={onSuccess}
            scrollToPlans={scrollToPlans}
            close={close}
          />
        ) : mode === "confirm-email" ? (
          <ConfirmRegisterInfo />
        ) : mode === "login" ? (
          <LoginForm
            switchToRegister={() => setMode("register")}
            switchToResetPass={() => {
              showResetPassPopup();
              close();
            }}
            setIsLoading={setIsLoading}
            close={close}
            scrollToPlans={scrollToPlans}
            onSuccess={onSuccess}
          />
        ) : (
          <></>
        )}
      </div>
    </BaseModal>
  );
};

export default LoginPopup;
